export const ContactHrefs = {
  linkedIn: "https://www.linkedin.com/in/ulisses-marciano/",
  github: "https://github.com/ulissesmarciano",
  whatsapp:
    "https://api.whatsapp.com/send?phone=+5513996484716&text=Ol%C3%A1+Ulisses+quero+conversar+com+voc%C3%AA%21+%F0%9F%98%80",
  email: "ulissesmarcianodev@gmail.com",
};

export const ProjectName = {
    ccem: "site-react-ccem-escola-de-musica",
    hubkut: "hubkut",
    pokedex: "react-pokedex",
};
